import React from "react";

export const DESCRIPTION_MONTHLY_CONTENT = {
  disableDescriptionOnRight: true,
  disableDescriptionOnLeft: true,

  oaDescriptions: [
    {
      title: "1,000+ Exclusive Amazon Products",
      description:
        "Receive 19 of the top 1% profitable products delivered to you weekly! Each one is selected from over 600 million products by AI.",
      translucent: false,
      addition: "annual",
    },
    {
      title: "12 Monthly Trend Reports",
      description:
        "Receive reports that include monthly product trends, profitable niches, Amazon news, insights, and tips on maximizing your profits.",
      translucent: false,
      addition: "annual",
    },
    {
      title: "PRO Extension",
      description:
        "Find and verify profitable products, and see if they are trending upward or downward. Check competition levels, get sourcing options and improve the quality of your listing.",
      translucent: false,
    },
    {
      title: "AMZScout Seller's Course",
      description:
        "Comprehensive and actionable information on how to start selling profitable products on Amazon in less than three months, without your own brand.",
      translucent: false,
    },
    {
      title: "Product Database & Product Tracker",
      description:
        "Generate profitable product ideas, select the best product from a profitable niche, and check product performance with daily tracking.",
      translucent: false,
    },
    {
      title: "Amazon Keyword Search & Reverse ASIN Lookup",
      description:
        "Gather the best keywords for your listings to attract customers via search and PPC campaigns.",
      translucent: false,
    },
    {
      title: "Keyword Tracker",
      description:
        "See organic positions and monitor keyword rankings to run PPC with high output keywords.",
      translucent: false,
    },
    {
      title: "Quick View",
      description:
        "See instant product sales data as if you were the seller, right on the search results page.",
      translucent: false,
    },
    {
      title: "Stock Stats",
      description:
        "Monitor competitors' stock size to gauge demand. Sell at a higher price when competitors run out of stock.",
      translucent: false,
    },
  ],
  bundleDescriptions: [
    {
      title: "1,000+ Exclusive Amazon Products",
      description:
        "Receive 19 of the top 1% profitable products delivered to you weekly! Each one is selected from over 600 million products by AI.",
      translucent: false,
      addition: "2 years",
    },
    {
      title: "12 Monthly Trend Reports",
      description:
        "Receive reports that include monthly product trends, profitable niches, Amazon news, insights, and tips on maximizing your profits.",
      translucent: false,
      addition: "2 years",
    },
    {
      title: "PRO Extension",
      description:
        "Find and verify profitable products, and see if they are trending upward or downward. Check competition levels, get sourcing options and improve the quality of your listing.",
      translucent: false,
    },
    {
      title: "AMZScout Seller's Course",
      description:
        "Comprehensive and actionable information on how to start selling profitable products on Amazon in less than three months, without your own brand.",
      translucent: false,
    },
    {
      title: "Product Database & Product Tracker",
      description:
        "Generate profitable product ideas, select the best product from a profitable niche, and check product performance with daily tracking.",
      translucent: false,
    },
    {
      title: "Amazon Keyword Search & Reverse ASIN Lookup",
      description:
        "Gather the best keywords for your listings to attract customers via search and PPC campaigns.",
      translucent: false,
    },
    {
      title: "Keyword Tracker",
      description:
        "See organic positions and monitor keyword rankings to run PPC with high output keywords.",
      translucent: false,
    },
    {
      title: "Quick View",
      description:
        "See instant product sales data as if you were the seller, right on the search results page.",
      translucent: false,
    },
    {
      title: "Stock Stats",
      description:
        "Monitor competitors' stock size to gauge demand. Sell at a higher price when competitors run out of stock.",
      translucent: false,
    },  ],
};
export const DESCRIPTION_YEARLY_CONTENT = {
  oaDescriptions: [
    {
      title: "1,000+ Exclusive Amazon Products",
      description:
        "Receive 19 of the top 1% profitable products delivered to you weekly! Each one is selected from over 600 million products by AI.",
      translucent: false,
      addition: "annual",
    },
    {
      title: "12 Monthly Trend Reports",
      description:
        "Receive reports that include monthly product trends, profitable niches, Amazon news, insights, and tips on maximizing your profits.",
      translucent: false,
      addition: "annual",
    },
    {
      title: "PRO Extension",
      description:
        "Find and verify profitable products, and see if they are trending upward or downward. Check competition levels, get sourcing options and improve the quality of your listing.",
      translucent: false,
    },
    {
      title: "AMZScout Seller's Course",
      description:
        "Comprehensive and actionable information on how to start selling profitable products on Amazon in less than three months, without your own brand.",
      translucent: false,
    },
    {
      title: "Product Database & Product Tracker",
      description:
        "Generate profitable product ideas, select the best product from a profitable niche, and check product performance with daily tracking.",
      translucent: false,
    },
    {
      title: "Amazon Keyword Search & Reverse ASIN Lookup",
      description:
        "Gather the best keywords for your listings to attract customers via search and PPC campaigns.",
      translucent: false,
    },
    {
      title: "Keyword Tracker",
      description:
        "See organic positions and monitor keyword rankings to run PPC with high output keywords.",
      translucent: false,
    },
    {
      title: "Quick View",
      description:
        "See instant product sales data as if you were the seller, right on the search results page.",
      translucent: false,
    },
    {
      title: "Stock Stats",
      description:
        "Monitor competitors' stock size to gauge demand. Sell at a higher price when competitors run out of stock.",
      translucent: false,
    },
  ],
  bundleDescriptions: [
    {
      title: "1,000+ Exclusive Amazon Products",
      description:
        "Receive 19 of the top 1% profitable products delivered to you weekly! Each one is selected from over 600 million products by AI.",
      translucent: false,
      addition: "2 years",
    },
    {
      title: "12 Monthly Trend Reports",
      description:
        "Receive reports that include monthly product trends, profitable niches, Amazon news, insights, and tips on maximizing your profits.",
      translucent: false,
      addition: "2 years",
    },
    {
      title: "PRO Extension",
      description:
        "Find and verify profitable products, and see if they are trending upward or downward. Check competition levels, get sourcing options and improve the quality of your listing.",
      translucent: false,
    },
    {
      title: "AMZScout Seller's Course",
      description:
        "Comprehensive and actionable information on how to start selling profitable products on Amazon in less than three months, without your own brand.",
      translucent: false,
    },
    {
      title: "Product Database & Product Tracker",
      description:
        "Generate profitable product ideas, select the best product from a profitable niche, and check product performance with daily tracking.",
      translucent: false,
    },
    {
      title: "Amazon Keyword Search & Reverse ASIN Lookup",
      description:
        "Gather the best keywords for your listings to attract customers via search and PPC campaigns.",
      translucent: false,
    },
    {
      title: "Keyword Tracker",
      description:
        "See organic positions and monitor keyword rankings to run PPC with high output keywords.",
      translucent: false,
    },
    {
      title: "Quick View",
      description:
        "See instant product sales data as if you were the seller, right on the search results page.",
      translucent: false,
    },
    {
      title: "Stock Stats",
      description:
        "Monitor competitors' stock size to gauge demand. Sell at a higher price when competitors run out of stock.",
      translucent: false,
    },  ],
};

export const SWITCH_CONTENT = {
  disableSwitch: false,
  firstButtonText: "Single",
  secondButtonText: "Bundles",
};
export const TITLE_BLOCKS_CONTENT = {
  titlesMonthly: { oaTitle: "Monthly", bundleTitle: "Annual" },
  titlesYearly: { oaTitle: "Annual", bundleTitle: "Lifetime" }
};